export const ABBREVIATION = "abbreviation";
export const ADD = "ADD";
export const adminData = {
    PROPS_ACTIVE_INACTIVE: "activeInactive",
    PROPS_ACTIVE_YN: "activeYn",
    PROPS_CAN_DELETE: "canDelete",
    PROPS_ENROLLED_YN: "enrolledYn",
    PROPS_ID: "id",
    PROPS_IMPORT_EXPORT: "importExport",
    PROPS_INTEGER_ID: "integerId",
    PROPS_IS_ACTIVE: "isActive",
    PROPS_MODIFIED_DATE: "modifiedDate",
    PROPS_NAME: "name",
    PROPS_ORG_LEVEL_LIST: "orgLevelList",
    PROPS_STATE_ABBREVIATION: "stateAbbreviation",
};
export const CANCEL = "cancel";
export const DAYS = "days";
export const DETAILS = "details";
export const EDIT = "EDIT";
export const FALSE = "false";
export const GUID = "guid";
export const ID = "id";
export const IS_EDITING = "isEditing";
export const LABEL = "label";
export const LABELNAME = "labelName";
export const NAME = "name";
export const NO = "No";
export const PICKLIST_GUID = "picklistValueGuid";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";
export const SAVE = "save";
export const SUBMIT = "submit";
export const TRUE = "true";
export const VALUE = "value";
export const YES = "Yes";
